import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface Props {
	handleAmountChange: (amount: number) => void;
	selectedAmount: number;
}

interface AmountBoxProps {
	isActive?: boolean;
}

const Container = styled.div``;

const AmountInput = styled.input`
	width: 70%;
	border: 1px solid #ddd;
	border-radius: 0;
	padding: 0.2em !important;
	line-height: 1.2em;
	text-align: center;
	margin-bottom: 0.5em;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&[type='number'] {
		-moz-appearance: textfield;
	}
`;

const StyledAmountList = styled.div`
	display: flex;
`;

const AmountBox = styled.div<AmountBoxProps>`
	flex-basis: 20%;
	max-width: 80px;
	height: 80px;
	border: 1px solid ${({ isActive }) => (isActive ? '#8ab9d2' : '#ddd')};
	background: ${({ isActive }) => (isActive ? '#dde9f5' : '#fbfbfb')};
	color: ${({ isActive }) => (isActive ? '#152e45' : '#575757')};
	margin-right: 1em;
	padding: 0.5em;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-weight: bold;
	transition: all 0.3s ease-in-out;
	flex-direction: column;

	&:last-child {
		margin-right: 0;
	}
`;

const OPTIONS = [
	{ label: '50 zł', amount: 50 },
	{ label: '100 zł', amount: 100 },
	{ label: '200 zł', amount: 200 },
] as const;

function AmountSelector({ handleAmountChange, selectedAmount }: Props) {
	const [selected, setSelected] = useState<string | null>(null);

	useEffect(() => {
		handleAmountChange(
			OPTIONS.find((opt) => opt.label === selected)?.amount || selectedAmount,
		);
	}, [selected, handleAmountChange, selectedAmount]);

	return (
		<Container>
			<StyledAmountList>
				{OPTIONS.map((opt) => (
					<AmountBox
						key={opt.label}
						isActive={selected === opt.label}
						onClick={() => {
							setSelected(opt.label);
						}}
					>
						{opt.label}
					</AmountBox>
				))}
				<AmountBox
					isActive={selected === 'Other'}
					onClick={() => {
						setSelected('Other');
					}}
				>
					<AmountInput
						type="number"
						value={selectedAmount}
						onClick={() => {
							setSelected('Other');
						}}
						onChange={(e) => {
							const amount = parseInt(e.target.value);
							handleAmountChange(!isNaN(amount) ? amount : 0);
						}}
					/>
					Inna
				</AmountBox>
			</StyledAmountList>
		</Container>
	);
}

export default AmountSelector;
