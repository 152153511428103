import styled from 'styled-components';
import React, { useState } from 'react';
import AmountSelector from './AmountSelector';

const StyledForm = styled.div`
	padding: 1em;
	max-width: 600px;
	font-family: Roboto, sans-serif;
`;

const StyledTitle = styled.h3`
	padding: 0;
	margin: 1em 0;
`;

const Donate = styled.button`
	margin-top: 1em;
	background: forestgreen;
	border: 0;
	padding: 1em 2em;
	border-radius: 50px;
	color: #fff;
	font-weight: bold;
	cursor: pointer;

	&[disabled] {
		filter: grayscale(100%);
		cursor: not-allowed;
	}
`;

const Error = styled.div`
	color: firebrick;
	border-left: 6px solid firebrick;
	padding: 0.5em;
	margin: 1em 0;
`;

export default function Form() {
	const [amount, setAmount] = useState(0);
	const [error, setError] = useState<null | string>(null);

	const handleAmountChange = (amount: number) => {
		setAmount(amount);
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (amount > 0) {
			const p24amount = amount * 100;
			const URL = `https://sklep.przelewy24.pl/zakup.php?z24_id_sprzedawcy=131072&z24_crc=3de462f0&z24_return_url=http%3A%2F%2Fwolnoscpodlupa.slib.pl/?info=thankyou&z24_language=pl&z24_nazwa=Wsparcie+projektu+Wolnosc+pod+Lupa&z24_kwota=${p24amount}`;

			window.location.href = URL;
		} else {
			setError('Wystąpił błąd. Spróbuj ponownie później');
		}
	};

	return (
		<StyledForm>
			<form onSubmit={handleSubmit}>
				<StyledTitle>Wybierz kwotę darowizny</StyledTitle>
				<AmountSelector
					handleAmountChange={handleAmountChange}
					selectedAmount={amount}
				/>
				{error && <Error>{error}</Error>}
				<Donate type="submit" disabled={amount <= 0}>
					Wpłać
				</Donate>
			</form>
		</StyledForm>
	);
}
